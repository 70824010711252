import PropTypes from 'prop-types'
import React from 'react'
import band1 from '../images/twicehouse_20191214_5.png'
import poster1 from '../images/colorado-river-boys-green.png'
import ryan from '../images/players/ryan2.png'
import corey from '../images/players/corey2.png'
import ben from '../images/players/ben.jpeg'
import matt from '../images/players/matt2.png'
import dale from '../images/players/dale.png'
import jesse from '../images/players/jesse.jpeg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="band"
          className={`${this.props.article === 'band' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Band</h2>
          {/* <span className="image main">
            <img src={band1} alt="" />
          </span> */}

          <h3>Ryan Tuttle</h3>
          <p>
          <span className="image left">
            <img src={ryan} alt="Ryan Tuttle, frontman with Colorado River Boys" />
          </span>
            <h4>Lead Vocals</h4>
            <h4>Acoustic Guitar</h4>
              Ryan Tuttle is a vocalist that has been singing and performing from a young age.
              Born and raised in Austin, Ryan grew up surrounded by country music and learned to play guitar in San Marcos 
              in the late 90’s.  Being the frontman for CRB is fulfilling a life-long dream to play the music he loves. 
              Ryan lives in Cedar Park with his beautiful wife and 2 children. Ryan is also active in the children’s music
              ministry of Hill Country Bible Church and can be found most Sunday mornings playing and singing worship songs
              with the kids.
          </p>

          <h3>Corey Butler</h3>
          <p>
          <span className="image left">
            <img src={corey} alt="Corey Butler, guitarist with Colorado River Boys" />
          </span>
            <h4>Lead Guitar</h4>
            <h4>Back Up Vocals</h4>
            <h4>Pedal Steel</h4>
              Corey Butler is an American musician singer-song writer from Baton Rouge, LA who has served as a sideman in several touring bands. 
              Best known as the guitar player for touring indie rock bands from Austin, TX such as nogood boyo and the Normans.
              While on tour in the 90's Corey opened for band such as White Zombie and Revernd Horton Heat, and played at iconic venues like CBGBs in NYC. 
          </p>

          <h3>Jesse Cravens</h3>
          <p>
          <span className="image left">
            <img src={jesse} alt="Jesse Cravens, Bass Player with Colorado River Boys" />
          </span>
            <h4>Bass</h4>
            <h4>Vocals</h4>
            Jesse Cravens is a bass player and singer songwriter based out of Austin, TX. 
            In the late 1990's, Jesse spent three years touring the continental US, playing over 200 shows a year, and learning the ropes as a frontman, 
            songwriter, bass player. During that time, Jesse opened for Colonel Bruce Hampton and the Codetalkers, JJ Grey and Mofro, Vasser Clements and Northern Lights, Ekoostic Hookah, 
            Perpetual Groove to name a few and played at iconic venues such as the Cleveland's Agora Theater, the Newport Music Hall in Columbus, Colorado's Rico Theater during the 2001 Telluride Blugrass Festival, 
            a late night show at the 2002 New Orleans Jazz Festival, the Pourhouse in Raleigh, NC and Austin's own Flamingo Cantina and Momo's.
            <br/>
            Later, betwen 2007-2009, Jesse joined the Pittsburgh-based band Peacefield as their bass player to help build their new Texas sound, and recorded the bass tracks on 'Stars Away'
            which was produced by Frogville Records producer Bill Palmer of Austin's Sharecroppers and Hundred Year Flood fame.
          </p>

          {/* <h3>Ben Morgan</h3>
          <p>
          <span className="image left">
            <img src={ben} alt="Ben Morgan, drummer with Colorado River Boys" />
          </span>
          <h4>Drums and Percussion</h4>
            Ben Morgan is a jazz drummer who first recorded in 1995 and has played with some of the greats, 
            such as Count Basie Orch, Al Grey, Keith Adams, and Chicago legend Brent Turney.  
            Ben also was awarded top percussion soloist at the Walt Disney World jazz competition.  
            He has been in rock bands, symphonies, orchestras, jazz ensembles, and taught drum line to inner-city kids.  
            Ben is from West Philadelphia born and raised, and in the music room where he spent most of his days.  
            He now resides in Austin and is enjoying CRB and his first county band.           
          </p>

          <h3>Matt Seabaugh</h3>
          <p>
          <span className="image left">
            <img src={matt} alt="Matt Seabaugh, keyboardist with Colorado River Boys" />
          </span>
          <h4>Keyboards and Pianos</h4>
          <h4>Vocals</h4>
          Bio coming soon.
            <br/>
            <br/>
          </p>

          <h3>Dale Seabaugh</h3>
          <p>
          <span className="image left">
            <img src={dale} alt="Dale Seabaugh, harmonicas with Colorado River Boys" />
          </span>
          <h4>Harmonica</h4>
          <h4>Vocals</h4>
          Bio coming soon.
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
          </p> */}
          {close}
        </article>

        <article
          id="shows"
          className={`${this.props.article === 'shows' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Shows</h2>

          <h3>2023.04.22 <a
                href="https://thewellevent.org/"
                className=""
              >The Well Event, Shooting Star Ranch, Liberty Hill, TX</a></h3>
          <p>
            with Billy Curtis
          </p>

          <h3>2023.03.03 <a
                href="https://rodeoaustin.com/"
                className=""
              >Austin Rodeo, Austin TX, Travis County Exposition Center</a></h3>
          <p>
            8:30-9:30PM Opener for Kevin Fowler
            with Billy Curtis
          </p>

          <h3>2022.10.15 <a
                href="https://laketravis.com/events/8th-annual-rods-ribs-car-show-cook-off/"
                className=""
              >8th Annual Rods and Ribs, Crosswater Yacht Club, Lakeway TX</a></h3>
          <p>
            with Billy Curtis
          </p>
          
          <h3>2022.9.15 <a
                href="https://cypressbend.org/f/cbna-oktoberfest-2021"
                className=""
              >Private Event: Hill Country Bible Church, Cedar Park TX</a></h3>
          <p>
            with Billy Curtis
          </p>

          <h3>2022.7.15 <a
                href=""
                className=""
              >Private Event: Hill Country Bible Church, Cedar Park TX</a></h3>
          <p>
            with Billy Curtis
          </p>

          <h3>2021.10.23 <a
                href="https://cypressbend.org/f/cbna-oktoberfest-2021"
                className=""
              >Cypress Bend Oktoberfest, Cedar Park TX</a></h3>

          <h3>2021.10.16 <a
                href="https://laketravis.com/events/7th-annual-rods-ribs-car-show-cook-off/"
                className=""
              >7th Annual Rods and Ribs, Crosswater Yacht Club, Lakeway TX</a></h3>
          <p>
          with Ben Wilson
          </p>
          <h3>2021.6.12 Twin Creeks IceHouse, Cedar Park TX</h3>
          <p>
          with Ben Wilson, Sam Davidson
          </p>
          <h3>2020.3.7 Austin Rodeo, Austin TX, Travis County Exposition Center</h3>
          <p>
          8:30-9:30PM Opener for Rick Trevino
          </p>
          {/* <span className="image main">
            <img src={poster1} alt="" />
          </span> */}
          <h3>2020.2.1 Twin Creeks IceHouse, Cedar Park TX</h3>
          <p>
          with special guest Ben Wilson of Blues Traveler
          </p>
          <span className="image main">
            <img src={poster1} alt="" />
          </span>
          <h3>2019.12.15 Twin Creeks IceHouse, Cedar Park TX</h3>
          <p>
            with The Tone Lepers
          </p>
          <h3>2019.12.12 Private Party, Cedar Park TX</h3>
          {close}
        </article>

        <article
          id="music"
          className={`${this.props.article === 'music' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Music</h2>

          <h3>2021.6.12</h3>
          <h4>Twin Creeks Icehouse, Volente TX</h4>

          <span className="image main">
          <iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1318489846&color=%23d6a456&auto_play=true&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false"></iframe>
          {/* <div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;">
            <a href="https://soundcloud.com/sessionswest" 
            title="SESSIONS WEST" 
            target="_blank" 
            style="color: #cccccc; text-decoration: none;">
              SESSIONS WEST
              </a> · 
              <a href="https://soundcloud.com/sessionswest/sets/2021-06-12-crb-twin-creeks" 
              title="2021.06.12 - CRB - Twin Creeks Icehouse, Volente, TX" 
              target="_blank" 
              style="color: #cccccc; text-decoration: none;">
                2021.06.12 - CRB - Twin Creeks Icehouse, Volente, TX
              </a>
          </div> */}
          {/* <h3>2020.2.01 CRB with Ben Wilson - Pride & Joy</h3>
          <h4>Twin Creeks Icehouse, Volente TX </h4>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/Yl19PFbDKVA" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <br/>
          <br/>
          <h3>2019.12.14 CRB - 7 & 7</h3>
          <h4>Twin Creeks Icehouse, Volente TX </h4>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/-_dN6DuQM6o" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
          </span>
          {close}
        </article>

        <article
          id="booking"
          className={`${this.props.article === 'booking' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Book Us</h2>
          <form name="booking" method="post" action="/success" data-netlify="true" data-netlify-honeypot="bot-field">
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="booking" />
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          <ul className="icons">
            <li>
              <a
                href="https://twitter.com/coriverboys"
                className="icon fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/coloradoriverboys" className="icon fa-facebook">
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/coloradoriverboys/" className="icon fa-instagram">
                <span className="label">Instagram</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCxWq4s14Pz3vur1TRC9o47A"
                className="icon fa-youtube"
              >
                <span className="label">Youtube</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
