import React from 'react'
import PropTypes from 'prop-types'

const Footer = (props) => (
    <footer id="footer" style={props.timeout ? {display: 'none'} : {}}>
{/* 
        <iframe 
            width="100%" 
            height="300" 
            scrolling="no" 
            frameborder="no" 
            allow="autoplay" 
            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1173018613&color=%23848484&auto_play=true&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true">
        </iframe>
        <div >
            <a href="https://soundcloud.com/arrowheadrecords" 
                title="ARROWHEAD RECORDS" 
                target="_blank">
            ARROWHEAD RECORDS</a> · 
            <a href="https://soundcloud.com/arrowheadrecords/sets/colorado-river-boys-live" 
                title="Colorado River Boys Live" 
                target="_blank" 
                style={{}}>
                    Colorado River Boys Live
            </a>
        </div> */}
        
        <ul className="icons">
            <li>
                <a
                href="https://twitter.com/coriverboys"
                className="icon fa-twitter"
                >
                <span className="label">Twitter</span>
                </a>
            </li>
            <li>
                <a href="https://www.facebook.com/coloradoriverboys" className="icon fa-facebook">
                <span className="label">Facebook</span>
                </a>
            </li>
            <li>
                <a href="https://www.instagram.com/coloradoriverboys/" className="icon fa-instagram">
                <span className="label">Instagram</span>
                </a>
            </li>
            <li>
                <a
                href="https://www.youtube.com/channel/UCxWq4s14Pz3vur1TRC9o47A"
                className="icon fa-youtube"
                >
                <span className="label">Youtube</span>
                </a>
            </li>
        </ul>
        <p className="copyright">Colorado River Boys 2022</p>
    </footer>
)

Footer.propTypes = {
    timeout: PropTypes.bool
}

export default Footer
